@import "~@/styles/util/_index.scss";

.clebex-section-input-label {
  font-size: 16px !important;
}
.clebex-item-content-wrapper.alternate {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}
.anonymous-services {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  margin-left: 17px;
}
